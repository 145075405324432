import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { TrainingDescription, TrainingBooking } from '../components/Trainings';
import { FluidImage, Training } from '../components/Trainings/types';

interface Props {
  data: {
    placeholder: {
      edges: Array<{ node: FluidImage }>;
    };
    crpm: {
      training: Training;
    };
    prismic: {
      allTrainings: {
        edges: Array<{
          node: {
            training_pictureSharp: FluidImage;
            tms_data: { id: string };
          };
        }>;
      };
    };
  };
}

export default function TrainingTemplate({
  data: {
    placeholder,
    crpm: { training },
    prismic: { allTrainings },
  },
}: Props) {
  const { subtitle, name, id } = training;

  const cmsData = allTrainings.edges.find(
    ({ node }) => node.tms_data.id === id,
  );

  return (
    <Layout
      // headerType="fixed"
      heroImage={
        (cmsData && cmsData.node.training_pictureSharp) ||
        placeholder.edges[0].node
      }
      subtitle={subtitle}
      title={name}
    >
      <Seo title={`CRPM - ${name}`} />
      <TrainingDescription
        training={{ ...training, ...(cmsData && cmsData.node) }}
      />
      <TrainingBooking trainingId={training.id} />
    </Layout>
  );
}

export const query = graphql`
  query getTraining($courseId: ID!) {
    placeholder: allFile(filter: { name: { regex: "/placeholder/" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    crpm {
      training(id: $courseId) {
        id
        name
        program
        objective
        method
        additionalInformation
        preRequisites
        audience
        days
      }
    }
    prismic {
      allTrainings {
        edges {
          node {
            internal_name
            training_picture
            training_pictureSharp {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tms_data
            subtitle
            introduction
            objectives
            concerned
            agenda
            prerequisites
            additional_information
          }
        }
      }
    }
  }
`;
